/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://cq12b7tcf0.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "expenslyREST",
            "endpoint": "https://bbx692x960.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://v3edbhtz2vewdjaqhiv3atnczq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:c057f440-2dd6-4fca-bc8a-403d0cf0f592",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_azyClPqpP",
    "aws_user_pools_web_client_id": "2ifdna67n73nv5l3vu0bcsr349",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "12",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "expensly154123-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
